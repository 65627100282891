<template>
  <div class="detailBox">
    <div class="detailContent" v-html="pageContent.content"></div>
  </div>
</template>
<script>
export default {
  props: {
    gropId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      pageContent: {}
    }
  },
  mounted() {
    var _this = this
    _this.getList()
  },
  methods: {
    async getList() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: _this.gropId, page: 1, pageSize: 10});
      _this.getDetail(data.data.list[0].id)
    },
    async getDetail(id) {
      var _this = this;

      var { data } = await _this.$http.post( _this.apiUrl + "/news/getNewsDetail", { id: id });
      _this.pageContent = data.data;
    },
  }
}
</script>