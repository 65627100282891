<template>
  <div class="listBox">
    <ul class="listList">
      <li v-for="(item, index) in listData" :key="index">
        <a class="clearfloat" target="_blank" :href="!item.is_link || item.is_link === 0 ? `${apiName}detail?id=${item.id}` : item.link_url">
          <div class="time fr">
            <h3>{{dayjs(item.create_time).format('MM-DD')}}</h3>
            <p>{{dayjs(item.create_time).format('YYYY')}}</p>
          </div>
          <div class="inner single-line">
            {{item.title}}
          </div>
        </a>
      </li>
    </ul>
    <div class="listPage">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="pageNum"
        :page-size="pageSize"
        :total="total"
        @current-change="currentChange">
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    gropId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      pageSize: 20,
      pageNum: 1,
      total: 0,
      listData: []
    }
  },
  filters: {
    //超过100位显示 ...
    ellipsis: function(value) {
      if (!value) return ''
      if (value.length > 120) {
        return value.slice(0, 120) + '...'
      }
      return value
    }
  },
  mounted() {
    var _this = this
    _this.getList()
  },
  methods: {
    async getList() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: _this.gropId, page: _this.pageNum, pageSize: _this.pageSize});
      _this.total = data.data.total
      _this.listData = data.data.list;
    },
    currentChange(val) {
      var _this = this
      _this.pageNum = val
      _this.getList()
    }
  }
}
</script>